import React, { Component } from 'react';
import Highcharts from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';
import HC_patternFill  from 'highcharts-pattern-fill/pattern-fill-v2';

import { getEuropeanMapData } from '../../../api';

import { connect } from 'react-redux';
import { europeanMapLabel } from '../../../model/PageLabels';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/offline-exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

HC_patternFill(Highcharts);

function fillTooltipData(pointParam,auxSeries,answerOrder, answer,literals) {
    let counter = 0;
    let tooltipString = "";
    let first = false;
    for (let serie in answerOrder) {
        if(!first){
            let found = auxSeries[answerOrder[serie]].find(element => element.country.toLowerCase() === pointParam.point["hc-key"]);
            tooltipString = '<div class="dvt-map-tooltip">'+'<p class="country-name">'+literals['L'+found.countryName]+'</p><ul>';
            first = true;
        }

        // Check if the answer is the selected one so the text in the tooltip is underlined
        if(answer.toString() === answerOrder[serie].toString()){
            tooltipString = tooltipString.concat('<li class="data'+counter+'"><span style="text-decoration: underline;">'+auxSeries[answerOrder[serie]][0].answerName+': '
                +pointParam.point.value+'%</span></li>');
        }else{
            let found = auxSeries[answerOrder[serie]].find(element => element.country.toLowerCase() === pointParam.point["hc-key"]);
            if(found){
                tooltipString = tooltipString.concat('<li class="data'+counter+'">'+found.answerName+': '+found.value+'%</li>');
            }
        }
        
        counter++;
    }
    tooltipString = tooltipString.concat('</ul></div>');
    return tooltipString
}

class EuropeanMap extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            select: "",
            chartConfig: {
				chart: {
					map: require('../../../model/map.europe.geo.json'),
					spacingBottom: 10,
                    height: window.innerWidth > 768 ? window.innerWidth > 1200 ? 95+'%' : 800 : 90+'%',
					backgroundColor: '#F0F0F0'
				},
			
				title: {
					text: this.props.title
				},

				credits: {
					enabled: false
				},

                exporting: {
                    enabled: false,
                    fallbackToExportServer: false
                },

				legend: {
                    enabled: false
				},
                yAxis: {
                    maxPadding: 0.01,
                },
                //hover brightness not working because of rgb with transparence. with hexadecimal works
				colorAxis: {
                    type: 'linear',
                    minColor: '#dcecec',
                    maxColor: '#00696C',
                    startOnTick: false,
                    endOnTick: false,
                    stops: [
                        [0, '#dcecec'],
                        [0.33, '#A8D0D1'],
                        [0.66, '#78B6B9'],
                        [1,'#00696C'],
                    ],
                    min: 0,
                    max: 100
                },
                plotOptions: {
                    map: {
                        nullColor: '#F0F0F0'
                    },
                    series: {
                        cursor: 'pointer',
                        states:{
                            hover: {
                                enabled: true,
                                borderColor: '#57575A'
                            }
                        },
                    }
                },
				series: [{
					dataLabels: {
						enabled: true,
						color: '#000000',
                        allowOverlap: true,
                        style: {
                            textOutline: '0px',
                            fontSize: window.innerWidth > 2400 ? "24px" : "14px",
                            font: "14px OpenSans"
                        },
						formatter: function () {
							if (this.point.value) {
                                if(this.point["hc-key"] === "gr"){
                                    return "EL";
                                }else if(this.point["hc-key"] === "gb"){
                                    return "UK";
                                }else if(this.point["hc-key"] === "hr"){
                                    this.point.properties['hc-middle-x'] = 0.50
                                    this.point.properties['hc-middle-y'] = 0.20
                                    return "HR";
                                }else if(this.point["hc-key"] === "nl"){
                                    if(window.innerWidth < 425){
                                        this.point.properties['hc-middle-x'] = 0.70
                                        this.point.properties['hc-middle-y'] = 0.20
                                    }else{
                                        this.point.properties['hc-middle-x'] = 0.60
                                        this.point.properties['hc-middle-y'] = 0.50
                                    }
                                    return "NL";
                                }else if(this.point["hc-key"] === "mt"){
                                    this.point.properties['hc-middle-y'] = -0.20;
                                    if (window.innerWidth < 425)
                                    {
                                        this.point.plotY = this.point.plotY - 4;
                                    }
                                    return "MT";
                                }else if(this.point["hc-key"] === "be"){
                                    if(window.innerWidth < 425){
                                        this.point.properties['hc-middle-x'] = 0.50;
                                        this.point.properties['hc-middle-y'] = 0.20;
                                    }
                                    return "BE";
                                }else{
                                    return this.point["hc-key"].toUpperCase();
                                }
							}
						}
					}
				}]
			},
            gaussChartConfig: {
                defs: {
                    patterns: [
                      {
                        id: "gauss-gradient1",
                        image: require("../../../style/img/color-range.png"),
                        width: 900,
                        height: 100
                      },
                      {
                        id: "gauss-gradient2",
                        image: require("../../../style/img/color-range.png"),
                        width: 600,
                        height: 100
                      },
                      {
                        id: "gauss-gradient3",
                        image: require("../../../style/img/color-range.png"),
                        width: 300,
                        height: 100
                      }
                    ]
                },
                chart: {
                    type: 'spline',
                    width: window.innerWidth > 768 ?  850 : window.innerWidth < 500 ? 250 : 550,
                    height: 230,
                },
            
                title: {
                    text: ''
                },
            
                yAxis: {
                    visible: false,
                    title: {
                        text: ''
                    },
                    max: 25,
                    min: -5,
                    gridLineWidth: 1,
                },
                
                legend: {
                    enabled: false
                },
                
                exporting: {
                    enabled: false,
                    fallbackToExportServer: false
                },
                
                credits: {
                    enabled: false
                },
            
                xAxis: {
                    width: window.innerWidth > 768 ?  700 : window.innerWidth < 500 ? 280 : 550,
                    lineWidth: 20,
                    lineColor: window.innerWidth > 768 ? 'url(#gauss-gradient1)' : window.innerWidth < 500 ? 'url(#gauss-gradient3)' : 'url(#gauss-gradient2)',
                    labels: {
                        y: 5,
                        style: {
                            color: "#FFFFF",
                            fontSize: "14px",
                            font: "14px OpenSans",
                            fontWeight: "Bold",
                            verticalAlign: 'center'
                        },
                        formatter: function () {
                            return this.value+'%';
                        }
                    },
                    top: -15
                },
            
                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false,                            
                        },
                        marker: {
                            enabled: false
                        },
                    },
                    spline: {
                        enableMouseTracking: false,
                        lineWidth: 5,
                        color: '#00696C'
                    }
                },
            
                series: [{
                    name: '',
                    data: [1, 0, 3, 11, 16]
                }],
            },
            isLoading: false
        }
    }

    updateDimension = () => {
        const width = window.innerWidth;

        // width control for gauss chart
        if (width > 767) {
            this.setState({
                gaussChartConfig: {
                    ...this.state.gaussChartConfig,
                    chart: {
                        ...this.state.gaussChartConfig.chart,
                        width: 700
                    },
                    xAxis: {
                        ...this.state.gaussChartConfig.xAxis,
                        lineColor: 'url(#gauss-gradient1)',
                        width: 700
                    }
                }
            });
        } else if (width < 767 && width > 551 ) {
            this.setState({
                gaussChartConfig: {
                    ...this.state.gaussChartConfig,
                    chart: {
                        ...this.state.gaussChartConfig.chart,
                        width: 550
                    },
                    xAxis: {
                        ...this.state.gaussChartConfig.xAxis,
                        lineColor: 'url(#gauss-gradient2)',
                        width: 550
                    }
                }
            });
        }

        if (width < 550) {
            this.setState({
                gaussChartConfig: {
                    ...this.state.gaussChartConfig,
                    chart: {
                        ...this.state.gaussChartConfig.chart,
                        width: 280
                    },
                    xAxis: {
                        ...this.state.gaussChartConfig.xAxis,
                        lineColor: 'url(#gauss-gradient3)',
                        width: 280
                    }
                }
            })
        }
        console.log(width)
        // Width control for euromap chart
        if (width > 1920) {
            this.setState({
                chartConfig: {
                    ...this.state.chartConfig,
                    chart: {
                        ...this.state.chartConfig.chart,
                        height: 95+'%'
                    },
                    series: [{
                        dataLabels: {
                            ...this.state.chartConfig.series[0].dataLabels,
                            style: {
                                textOutline: '0px',
                                fontSize: "24px",
                                font: "14px OpenSans"
                            }
                        }
                    }]
                }
            })
        } else if(width > 1064) {
            this.setState({
                chartConfig: {
                    ...this.state.chartConfig,
                    chart: {
                        ...this.state.chartConfig.chart,
                        height: 800
                    },
                    series: [{
                        dataLabels: {
                            ...this.state.chartConfig.series[0].dataLabels,
                            style: {
                                textOutline: '0px',
                                fontSize: "14px",
                                font: "14px OpenSans"
                            }
                        }
                    }]
                },
            })
        } else {
            this.setState({
                chartConfig: {
                    ...this.state.chartConfig,
                    chart: {
                        ...this.state.chartConfig.chart,
                        height: 90+'%'
                    }
                },
            })
        }
    }

    componentDidMount() {
        document.title = europeanMapLabel
		this.getLoadData(this.props.filters.question, this.props.filters.sectorSize, this.props.filters.activitySector, this.props.filters.establishmentSize, 
            this.props.filters.answer, this.props.filters.euOnly,this.props.filters.locale);

        window.addEventListener('resize', this.updateDimension);
	}

    componentDidUpdate(prevProps, prevState){
        if(prevProps.filters.question != this.props.filters.question || prevProps.filters.sectorSize != this.props.filters.sectorSize
            || prevProps.filters.activitySector != this.props.filters.activitySector || prevProps.filters.establishmentSize != this.props.filters.establishmentSize
            || prevProps.filters.euOnly != this.props.filters.euOnly || prevProps.filters.answer != this.props.filters.answer
            || prevProps.filters.locale != this.props.filters.locale){
            this.getLoadData(this.props.filters.question, this.props.filters.sectorSize, this.props.filters.activitySector, this.props.filters.establishmentSize, 
                this.props.filters.answer, this.props.filters.euOnly,this.props.filters.locale);
        }
        var charts = Highcharts.charts.filter(function( element ) {
            return element !== undefined;
        });
        charts[0].renderer.boxWrapper.attr({ preserveAspectRatio: 'xMinYMin' });
	}

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimension);
    }

    getLoadData = (question, sectorSize, activityId, establishmentId, answer, euOnly, locale) => {
		let auxSeries = [];
        let selectedAnswerData = [];
        let answerOrder = [];
        let minMaxValues = {
            minValue: 100,
            maxValue: 0,
            range: 25
        };
        let categories = [];
        let gaussIndexTotals = {
            index_1: 0,
            index_2: 0,
            index_3: 0,
            index_4: 0,
            index_5: 0
        }

        let csvData = [];

        let countryCode = "";

        let gaussSeries = [];

		getEuropeanMapData(question, sectorSize, sectorSize === "activity-sector" ? activityId : establishmentId, answer, euOnly, locale)
			.then((res) => {
                let literals = this.props.literals;
                res.resultset.forEach((element)=>{
                    let split1 = element.split1ID;
                    // If this is is the first time treating this answer
                    if (!(split1 in auxSeries)) {
                        auxSeries[split1] = [];
                        selectedAnswerData[split1] = [];
                        answerOrder.push(split1);
                    }
                    
                    if(element.countryCode != "EU28" && element.countryCode != "EU27_2020" ) {
                        if(element.countryCode === "EL"){
                            countryCode = "gr";
                        }else if(element.countryCode === "UK"){
                            countryCode = "gb";
                        }else{
                            countryCode = element.countryCode;
                        }
                        auxSeries[split1].push({country: countryCode, value: element.value, countryName: element.countryLiteralID,
                            answerName: element.split1, answerId: element.split1ID});
                        if(element.value){
                            selectedAnswerData[split1].push([countryCode.toLowerCase(), element.value]);
                        }
                    }

                    csvData.push({ country: element.countryCode, subset: element.split2, answer: element.split1, value: element.value });
                })

                this.props.csvDataCallback(csvData);

                for (let answerIndex in auxSeries[answer]) {
                    let answerData = auxSeries[answer][answerIndex];
                    if(answerData.value < minMaxValues.minValue){
                        minMaxValues.minValue = answerData.value;
                    }

                    if(answerData.value > minMaxValues.maxValue){
                        minMaxValues.maxValue = answerData.value;
                    }
                }

                if(minMaxValues.minValue == null){
                    minMaxValues.minValue = 0;
                }

				minMaxValues.range = (minMaxValues.maxValue - minMaxValues.minValue) / 4;

                categories.push(minMaxValues.minValue);
                categories.push(Math.round((minMaxValues.minValue + minMaxValues.range) * 10) / 10);
                categories.push(Math.round((categories[1] + minMaxValues.range) * 10) / 10);
                categories.push(Math.round((categories[2] + minMaxValues.range) * 10) / 10);
                categories.push(minMaxValues.maxValue);

                for (let answerIndex in auxSeries[answer]) {
                    let answerData = auxSeries[answer][answerIndex];
                    if(answerData.value === categories[0]){
                        gaussIndexTotals.index_1 = gaussIndexTotals.index_1+1
                    }else if(answerData.value > categories[0] && answerData.value <= categories[1]){
                        gaussIndexTotals.index_2 = gaussIndexTotals.index_2+1
                    }else if(answerData.value > categories[1] && answerData.value <= categories[2]){
                        gaussIndexTotals.index_3 = gaussIndexTotals.index_3+1
                    }else if(answerData.value > categories[2] && answerData.value <= categories[3]){
                        gaussIndexTotals.index_4 = gaussIndexTotals.index_4+1
                    }else if(answerData.value > categories[3] <= categories[4]){
                        gaussIndexTotals.index_5 = gaussIndexTotals.index_5+1
                    }
                }

                gaussSeries.push(gaussIndexTotals.index_1);
                gaussSeries.push(gaussIndexTotals.index_2);
                gaussSeries.push(gaussIndexTotals.index_3);
                gaussSeries.push(gaussIndexTotals.index_4);
                gaussSeries.push(gaussIndexTotals.index_5);

                // this.setState({chartConfig: {...this.state.chartConfig, series: [{  data: [['es',4],['it',87]]  }] }})
                this.setState({
                    chartConfig: {
                        ...this.state.chartConfig,
                        series: [{  data: selectedAnswerData[answer]  }],
                        tooltip: {
                            useHTML: true,
                            outside: true,
                            formatter: function(){ return fillTooltipData(this, auxSeries, answerOrder, answer, literals) }
                        },
                        colorAxis: {
                            ...this.state.chartConfig.colorAxis,
                            min: minMaxValues.minValue,
                            max: minMaxValues.maxValue
                        }
                    },
                    gaussChartConfig: {
                        ...this.state.gaussChartConfig,
                        xAxis: {
                            categories: categories
                        },
                        series: [{
                            data: gaussSeries
                        }]
                    }
                })
            });
		
	}

    render(){
        return(
            <div className="european-map">
                <section className="survey--map--block">
                    <div className="map--block center-text">
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={this.state.chartConfig}
                            constructorType='mapChart'
                            containerProps={{ className: 'map--block center-text' }}
                        />
                    </div>
                    <div className="map--gauss--block">
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={this.state.gaussChartConfig}
                            containerProps={{ className: 'map--gauss--block' }}
                        />
                    </div>
                </section>
			</div>
        )
    }
}

function mapStateToProps(state){
    const {literals} = state;
    return {
        literals
    }
}

// export default EuropeanMap;
export default connect(mapStateToProps, null)(EuropeanMap);